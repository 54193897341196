<template>
  <!-- 导航栏 -->
  <div class="navbar">
    <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#000"
        text-color="#fff"
        active-text-color="#ffd04b">
      <el-menu-item index="1" @click="navigateTo('/guanwang')">首页</el-menu-item>
      <el-submenu index="2">
        <template #title><span @click="navigateTo('/uwbdingweibiaoqian')">产品中心</span></template>
        <el-menu-item index="cp1" @click="navigateTo('/uwbdingweibiaoqian')">UWB微标签</el-menu-item>
        <el-menu-item index="cp2" @click="navigateTo('/uwbdingweijizhan')">UWB定位基站</el-menu-item>
        <el-menu-item index="cp3" @click="navigateTo('/uwbdingweixitong')">UWB定位系统</el-menu-item>
        <el-menu-item index="cp4" @click="navigateTo('/Uwbdingweiruanjian')">UWB定位软件</el-menu-item>
        <el-menu-item index="cp5" @click="navigateTo('/fangbaochanpin')">防爆产品</el-menu-item>
        <el-menu-item index="cp6" @click="navigateTo('/uwbdingweizdykfpt')">UWB定位自定义开发平台</el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template #title><span @click="navigateTo('/fangkeguanli')">应用方案</span></template>
        <el-menu-item index="yy1" @click="navigateTo('/fangkeguanli')">访客管理</el-menu-item>
        <el-menu-item index="yy2" @click="navigateTo('/zhinengkaoqin')">智能考勤</el-menu-item>
        <el-menu-item index="yy3" @click="navigateTo('/zhinengxunjian')">智能巡检</el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template #title><span @click="navigateTo('/zhinengzhizao')">行业方案</span></template>
        <el-menu-item index="hy1" @click="navigateTo('/zhinengzhizao')">智能制造</el-menu-item>
        <el-menu-item index="hy2" @click="navigateTo('/qiyeneicangchu')">企业内仓储</el-menu-item>
        <el-menu-item index="hy3" @click="navigateTo('/daxingchangsuo')">大型场所</el-menu-item>
        <el-menu-item index="hy4" @click="navigateTo('/shujuzhongxin')">数据中心</el-menu-item>
      </el-submenu>
      <el-menu-item index="5" @click="navigateTo('/fuwuyuzhichi')">服务与支持</el-menu-item>
      <el-menu-item index="6" @click="navigateTo('/guanyuwomen')">关于我们</el-menu-item>
      <el-menu-item index="7" @click="navigateTo('/lianxiwomen')">联系我们</el-menu-item>
      <el-menu-item index="8" @click="navigateTo('/login')">登 录 / 注 册</el-menu-item>
<!--      <el-menu-item index="9" @click="navigateTo('/login')">注册</el-menu-item>-->
    </el-menu>
  </div>

</template>

<script>
export default {
  data() {
    return {
      activeIndex2: '1' // 初始化默认值
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex2 = key;
    }
  },
  name: 'Ztl'
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中（如果需要） */
  background-color: black; /* 设置背景色为黑色 */
}
.el-menu-demo {
  width: fit-content; /* 确保菜单宽度只占据其内容的实际宽度 */
}
</style>
