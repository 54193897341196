<template>
  <footer class="footer">
    <div class="container">
      <div class="row">

        <div class="col">
          <h4>网站导航</h4>
          <ul class="list-unstyled">
            <li><a href="/guanwang">首页</a></li>
            <li><a href="/fuwuyuzhichi">服务与支持</a></li>
            <li><a href="/uwbdingweibiaoqian">产品中心</a></li>
            <li><a href="/guanyuwomen">关于我们</a></li>
            <li><a href="/lianxiwomen">联系我们</a></li>

          </ul>
        </div>
        <div class="col">
          <h4>行业方案</h4>
          <ul class="list-unstyled">
            <li><a href="/zhinengzhizao">智能制造</a></li>
            <li><a href="/qiyeneicangchu">企业内仓储</a></li>
            <li><a href="/daxingchangsuo">大型场所</a></li>
            <li><a href="/shujuzhongxin">数据中心</a></li>
          </ul>
        </div>

        <div class="col">
          <h4>应用方案</h4>
          <ul class="list-unstyled">
            <li><a href="/fangkeguanli">访客管理</a></li>
            <li><a href="/zhinengkaoqin">智能考勤</a></li>
            <li><a href="/zhinengxunjian">智能巡检</a></li>
          </ul>
        </div>

        <div class="col">
          <h4>扫码添加客服！  咨询报价及方案！</h4>
          <div class="d-flex justify-content-between">
            <img src="../assets/SYlxfs/wxeewm.jpg" alt="微信二维码" class="img-fluid qr-code" />
            <img src="../assets/SYlxfs/qqewm.jpg" alt="QQ二维码" class="img-fluid qr-code" />
          </div>
        </div>

      </div>
      <hr>
        <p class="copyright text-center">&copy; {{ new Date().getFullYear() }} 西藏凌空物语智能科技有限公司 版权所有</p>
        <p class="icp text-center"><a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">蜀ICP备12345678号-1</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  background-color: #c0c0c0;
  color: #000000;
  padding: 30px 0;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  flex: 1;
  margin-right: 20px;
  text-align: left;
}

.social-media .list-inline-item a {
  color: #fff;
  margin-right: 10px;
}

.social-media .list-inline-item a:hover {
  color: #ddd;
}

.copyright {
  margin-top: 20px;
  color: #000000;
}

.qr-code {
  max-width: 40%; /* 调整最大宽度以适应容器 */
  margin-top: 1rem; /* 上边距，可根据需要调整 */

  margin-right: 1rem; /* 右边距 */
}
</style>
