<template>
  <div class="main-container">
    <div class="navbar">
      <ul class="one">
        <li v-for="item in items" :key="item.id" class="modern-border">
          <a :href="item.href">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  name: "Cztl",
  data() {
    return {
      items: [
        { name: 'UWB微标签', href: '/uwbdingweibiaoqian',},
        { name: 'UWB定位基站', href: '/uwbdingweijizhan',},
        { name: 'UWB定位系统', href: '/uwbdingweixitong',},
        { name: 'UWB定位软件', href: '/Uwbdingweiruanjian',},
        { name: '防爆产品', href: '/fangbaochanpin',},
        { name: 'UWB定位自定义开发平台', href: '/uwbdingweizdykfpt',},

      ],

    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh; /* 设置容器高度为视口高度 */

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3b3b3b;
  padding: 20px;
  color: #ffffff; /* 设置 .navbar 内所有文字颜色为白色 */
  border-radius: 10px;
}

.one {
  display: flex;
}
.one li a {
  color: #ffffff; /* 设置列表项字体颜色为白色 */
}
.one li {
  list-style-type: none;
  margin-right: 20px;
}

.modern-border {
  padding: 10px;
  border: 2px solid #fffefe;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.modern-border:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
</style>